import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { AppStoreService } from '../store/app-store.service';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private readonly appStoreService: AppStoreService,
        private readonly router: Router,
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        let authReq = req;
        if (this.appStoreService.user && this.appStoreService.user.accessToken) {
            const accessToken = this.appStoreService.user.accessToken;
            authReq = req.clone({ setHeaders: { Authorization: accessToken } });
        }
        return next.handle(authReq).pipe(tap(() => { },
            (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status !== 401) {
                        return;
                    }
                    this.appStoreService.clear();
                    this.router.navigate(['login']);
                }
            }));
    }
}
