import { Injectable } from '@angular/core';
import { io } from 'socket.io-client';
import { ShareSocketMsgService } from 'src/app/services/api/share-socket-msg.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SocketioService {

  private socket : any;
  private url : string;

  constructor(private shareSocketMsgService: ShareSocketMsgService) { }

  setupSocketConnection(accessToken: string) {
    this.url = document.location.host == "localhost:4200" ? "https://test.skilly.hu" : `https://${document.location.host}`;

    this.socket = io(this.url, {
      path: "/ws",
      transports: ["websocket", "polling"],
      auth: {
        //token: 'SkillxSocketIO'
        token: accessToken
      },
      reconnectionAttempts: 15,
      autoConnect: true
    });

    this.socket.emit('notification', true);

    this.socket.on('notifications', (data: number) => {
      this.shareSocketMsgService.changeNotification(data);
    })
  }

  getNewMessage(){
    return new Observable(observer => {
      this.socket.on('msg', (data: object) => {
        observer.next(data);
      })
      return () => {
        this.socket.off('msg');
      };
    });
  }

  sendMessage(data: object) {
    this.socket.emit('msg', data);
  }

  readNotification(data: string) {
    this.socket.emit('read-notification', data);
  }

  disconnect() {
    if(this.socket){
      this.socket.disconnect();
    }
  }
}
