/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ChangePasswordRequestDto } from '../model/changePasswordRequestDto';
import { passwordStrengthRequestDto } from '../model/passwordStrengthRequestDto';
import { ChangePasswordTokenRequestDto } from '../model/changePasswordTokenRequestDto';
import { PasswordStrengthDto } from '../model/passwordStrengthDto';
import { UserInfoDto } from '../model/userInfoDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class UserControllerService {

    protected basePath = 'https://skillx-dev.molaris.local:8888';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * acceptGtc
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public acceptGtcUsingPATCH(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public acceptGtcUsingPATCH(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public acceptGtcUsingPATCH(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public acceptGtcUsingPATCH(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (JWT) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.patch<any>(`${this.basePath}/user/gtc/accept`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * changePasswordToken
     * 
     * @param dto dto
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changePasswordTokenUsingPUT(dto: ChangePasswordTokenRequestDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public changePasswordTokenUsingPUT(dto: ChangePasswordTokenRequestDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public changePasswordTokenUsingPUT(dto: ChangePasswordTokenRequestDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public changePasswordTokenUsingPUT(dto: ChangePasswordTokenRequestDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (dto === null || dto === undefined) {
            throw new Error('Required parameter dto was null or undefined when calling changePasswordTokenUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWT) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/user/change_password_token`,
            dto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * changePassword
     * 
     * @param changePasswordRequestDto changePasswordRequestDto
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changePasswordUsingPUT(changePasswordRequestDto: ChangePasswordRequestDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public changePasswordUsingPUT(changePasswordRequestDto: ChangePasswordRequestDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public changePasswordUsingPUT(changePasswordRequestDto: ChangePasswordRequestDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public changePasswordUsingPUT(changePasswordRequestDto: ChangePasswordRequestDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (changePasswordRequestDto === null || changePasswordRequestDto === undefined) {
            throw new Error('Required parameter changePasswordRequestDto was null or undefined when calling changePasswordUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWT) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/user/change_password`,
            changePasswordRequestDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteUser
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteUserUsingDELETE(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteUserUsingDELETE(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteUserUsingDELETE(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteUserUsingDELETE(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteUserUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWT) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/user/manager/delete/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * forgottenPassword
     * 
     * @param email email
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public forgottenPasswordUsingPUT(email: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public forgottenPasswordUsingPUT(email: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public forgottenPasswordUsingPUT(email: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public forgottenPasswordUsingPUT(email: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling forgottenPasswordUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWT) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<any>(`${this.basePath}/user/forgotten_password/${encodeURIComponent(String(email))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getInfo
     * 
     * @param email email
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInfoUsingGET(email: string, observe?: 'body', reportProgress?: boolean): Observable<UserInfoDto>;
    public getInfoUsingGET(email: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserInfoDto>>;
    public getInfoUsingGET(email: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserInfoDto>>;
    public getInfoUsingGET(email: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling getInfoUsingGET.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWT) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<UserInfoDto>(`${this.basePath}/user/getinfo`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getPasswordStrength
     * 
     * @param password password
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPasswordStrengthUsingPOST(password: passwordStrengthRequestDto, observe?: 'body', reportProgress?: boolean): Observable<PasswordStrengthDto>;
    public getPasswordStrengthUsingPOST(password: passwordStrengthRequestDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PasswordStrengthDto>>;
    public getPasswordStrengthUsingPOST(password: passwordStrengthRequestDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PasswordStrengthDto>>;
    public getPasswordStrengthUsingPOST(password: passwordStrengthRequestDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (password === null || password === undefined) {
            throw new Error('Required parameter password was null or undefined when calling getPasswordStrengthUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWT) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PasswordStrengthDto>(`${this.basePath}/user/password_strength`,
            password,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * modifyUserInfo
     * 
     * @param userInfoDto userInfoDto
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */

    public modifyUserInfoUsingPUT(userInfoDto: UserInfoDto, observe?: 'body', reportProgress?: boolean): Observable<UserInfoDto>;
    public modifyUserInfoUsingPUT(userInfoDto: UserInfoDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserInfoDto>>;
    public modifyUserInfoUsingPUT(userInfoDto: UserInfoDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserInfoDto>>;
    public modifyUserInfoUsingPUT(userInfoDto: UserInfoDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userInfoDto === null || userInfoDto === undefined) {
            throw new Error('Required parameter userInfoDto was null or undefined when calling modifyUserInfoUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWT) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<UserInfoDto>(`${this.basePath}/user/modify_info`,
            userInfoDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * resetPassword
     * 
     * @param email email
     * @param token token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resetPasswordUsingGET(email: string, token: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public resetPasswordUsingGET(email: string, token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public resetPasswordUsingGET(email: string, token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public resetPasswordUsingGET(email: string, token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling resetPasswordUsingGET.');
        }

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling resetPasswordUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }

        let headers = this.defaultHeaders;

        // authentication (JWT) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/user/resetpassword`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get user list
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserListUsingGET(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getUserListUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getUserListUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getUserListUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

      let headers = this.defaultHeaders;

      // authentication (JWT) required
      if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
          headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
      }

      // to determine the Accept header
      let httpHeaderAccepts: string[] = [
          '*/*'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
          headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
      ];

      return this.httpClient.get<any>(`${this.basePath}/user/manager`,
          {
              withCredentials: this.configuration.withCredentials,
              headers: headers,
              observe: observe,
              reportProgress: reportProgress
          }
      );
    }

    /**
     * Add new user
     * 
     * @param userInfoDto userInfoDto
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
    */
    public addUserUsingPOST(userInfoDto: UserInfoDto, observe?: 'body', reportProgress?: boolean): Observable<UserInfoDto>;
    public addUserUsingPOST(userInfoDto: UserInfoDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserInfoDto>>;
    public addUserUsingPOST(userInfoDto: UserInfoDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserInfoDto>>;
    public addUserUsingPOST(userInfoDto: UserInfoDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userInfoDto === null || userInfoDto === undefined) {
            throw new Error('Required parameter userInfoDto was null or undefined when calling addUserUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWT) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UserInfoDto>(`${this.basePath}/user/manager/add`,
            userInfoDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }


    /**
     * getUser
     * 
     * @param email email
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<UserInfoDto>;
    public getUserUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserInfoDto>>;
    public getUserUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserInfoDto>>;
    public getUserUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getUserUsingGET.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWT) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<UserInfoDto>(`${this.basePath}/user/manager/get/${encodeURIComponent(Number(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add new user
     * 
     * @param userInfoDto userInfoDto
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
    */
    public modifyUserUsingPUT(id:number, userInfoDto: UserInfoDto, observe?: 'body', reportProgress?: boolean): Observable<UserInfoDto>;
    public modifyUserUsingPUT(id:number, userInfoDto: UserInfoDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserInfoDto>>;
    public modifyUserUsingPUT(id:number, userInfoDto: UserInfoDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserInfoDto>>;
    public modifyUserUsingPUT(id:number, userInfoDto: UserInfoDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        
        if (id === null || id === undefined) {
          throw new Error('Required parameter id was null or undefined when calling modifyUserUsingPUT.');
        }

        if (userInfoDto === null || userInfoDto === undefined) {
          throw new Error('Required parameter userInfoDto was null or undefined when calling modifyUserUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWT) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<UserInfoDto>(`${this.basePath}/user/manager/update/${encodeURIComponent(Number(id))}`,
            userInfoDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * reset Password
     * 
     * @param changePasswordRequestDto changePasswordRequestDto
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resetPasswordUsingPUT(id:number, changePasswordRequestDto: ChangePasswordRequestDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public resetPasswordUsingPUT(id:number, changePasswordRequestDto: ChangePasswordRequestDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public resetPasswordUsingPUT(id:number, changePasswordRequestDto: ChangePasswordRequestDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public resetPasswordUsingPUT(id:number, changePasswordRequestDto: ChangePasswordRequestDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (changePasswordRequestDto === null || changePasswordRequestDto === undefined) {
            throw new Error('Required parameter changePasswordRequestDto was null or undefined when calling changePasswordUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWT) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/user/manager/change_password/${encodeURIComponent(Number(id))}`,
            changePasswordRequestDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
