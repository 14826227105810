export * from './authenticationController.service';
import { AuthenticationControllerService } from './authenticationController.service';
export * from './basicErrorController.service';
import { BasicErrorControllerService } from './basicErrorController.service';
export * from './notificationController.service';
import { NotificationControllerService } from './notificationController.service';
export * from './registrationController.service';
import { RegistrationControllerService } from './registrationController.service';
export * from './userController.service';
import { UserControllerService } from './userController.service';
import { ProductControllerService } from './productController.service';
export * from './productController.service';
import { FilemanagerControllerService } from './filemanagerController.service';
export * from './filemanagerController.service';

export const APIS = [AuthenticationControllerService,
                    BasicErrorControllerService,
                    NotificationControllerService, 
                    RegistrationControllerService,
                    UserControllerService,
                    ProductControllerService,
                    FilemanagerControllerService
                  ];
