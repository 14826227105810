/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { NotificationDto } from '../model/notificationDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class NotificationControllerService {

    protected basePath = 'https://skillx-dev.molaris.local:8888';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * createNotification
     * 
     * @param code code
     * @param userId userId
     * @param extra extra
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createNotificationUsingPOST(code: 'APPLY_JOB' | 'DOWNLOADABLE_SKILLX_APP' | 'JOB_INTERVIEW' | 'NEW_JOB_OFFERING' | 'NEW_SELFKNOWLEDGE_TEST' | 'SUBSCRIPTION_ACTIVATED', userId: number, extra?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public createNotificationUsingPOST(code: 'APPLY_JOB' | 'DOWNLOADABLE_SKILLX_APP' | 'JOB_INTERVIEW' | 'NEW_JOB_OFFERING' | 'NEW_SELFKNOWLEDGE_TEST' | 'SUBSCRIPTION_ACTIVATED', userId: number, extra?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public createNotificationUsingPOST(code: 'APPLY_JOB' | 'DOWNLOADABLE_SKILLX_APP' | 'JOB_INTERVIEW' | 'NEW_JOB_OFFERING' | 'NEW_SELFKNOWLEDGE_TEST' | 'SUBSCRIPTION_ACTIVATED', userId: number, extra?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public createNotificationUsingPOST(code: 'APPLY_JOB' | 'DOWNLOADABLE_SKILLX_APP' | 'JOB_INTERVIEW' | 'NEW_JOB_OFFERING' | 'NEW_SELFKNOWLEDGE_TEST' | 'SUBSCRIPTION_ACTIVATED', userId: number, extra?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling createNotificationUsingPOST.');
        }

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling createNotificationUsingPOST.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (code !== undefined && code !== null) {
            queryParameters = queryParameters.set('code', <any>code);
        }
        if (extra !== undefined && extra !== null) {
            queryParameters = queryParameters.set('extra', <any>extra);
        }

        let headers = this.defaultHeaders;

        // authentication (JWT) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/notification/user/${encodeURIComponent(String(userId))}`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }


    /**
     * getMainNotificationDto
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMainNotificationDtoUsingGET(observe?: 'body', reportProgress?: boolean): Observable<NotificationDto>;
    public getMainNotificationDtoUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<NotificationDto>>;
    public getMainNotificationDtoUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<NotificationDto>>;
    public getMainNotificationDtoUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (JWT) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<NotificationDto>(`${this.basePath}/notification/mainnotifications`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }


    /**
     * getActualNotificationDto
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActualNotificationDtoUsingGET(observe?: 'body', reportProgress?: boolean): Observable<NotificationDto>;
    public getActualNotificationDtoUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<NotificationDto>>;
    public getActualNotificationDtoUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<NotificationDto>>;
    public getActualNotificationDtoUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (JWT) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<NotificationDto>(`${this.basePath}/notification/actual`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllNotificationDtos
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllNotificationDtosUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<NotificationDto>>;
    public getAllNotificationDtosUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<NotificationDto>>>;
    public getAllNotificationDtosUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<NotificationDto>>>;
    public getAllNotificationDtosUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (JWT) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<NotificationDto>>(`${this.basePath}/notification/all`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getCountUnreadNotification
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCountUnreadNotificationUsingGET(observe?: 'body', reportProgress?: boolean): Observable<number>;
    public getCountUnreadNotificationUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public getCountUnreadNotificationUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public getCountUnreadNotificationUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (JWT) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<number>(`${this.basePath}/notification/count`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getLabourNotificationDtos
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLabourNotificationDtosUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<NotificationDto>>;
    public getLabourNotificationDtosUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<NotificationDto>>>;
    public getLabourNotificationDtosUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<NotificationDto>>>;
    public getLabourNotificationDtosUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (JWT) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<NotificationDto>>(`${this.basePath}/notification/labour`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }



    /**
     * getContactNotificationDtos
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
         public getContactNotificationUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<NotificationDto>>;
         public getContactNotificationUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<NotificationDto>>>;
         public getContactNotificationUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<NotificationDto>>>;
         public getContactNotificationUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
     
             let headers = this.defaultHeaders;
     
             // authentication (JWT) required
             if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
                 headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
             }
     
             // to determine the Accept header
             let httpHeaderAccepts: string[] = [
                 '*/*'
             ];
             const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
             if (httpHeaderAcceptSelected != undefined) {
                 headers = headers.set('Accept', httpHeaderAcceptSelected);
             }
     
             // to determine the Content-Type header
             const consumes: string[] = [
             ];
     
             return this.httpClient.get<Array<NotificationDto>>(`${this.basePath}/notification/contact`,
                 {
                     withCredentials: this.configuration.withCredentials,
                     headers: headers,
                     observe: observe,
                     reportProgress: reportProgress
                 }
             );
         }




    /**
     * read
     * 
     * @param uuid uuid
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readUsingPATCH(uuid: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public readUsingPATCH(uuid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public readUsingPATCH(uuid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public readUsingPATCH(uuid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling readUsingPATCH.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWT) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.patch<any>(`${this.basePath}/notification/${encodeURIComponent(String(uuid))}/read`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
