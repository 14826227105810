/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */


import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface fileDto {
  file:string;
  path:string;
  newname?:string;
}

export interface directoryDto {
  directory:string;
  path:string;
  action:string;
  newname?:string;
}

@Injectable({
    providedIn: 'root'
})
export class FilemanagerControllerService {

    protected basePath = 'https://skillx-dev.molaris.local:8888';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }
    
    //Upload files..
    public uploadFileUsingPOST(fileDto:fileDto, observe?: 'body', reportProgress?: boolean): Observable<Array<fileDto>>;
    public uploadFileUsingPOST(fileDto:fileDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<fileDto>>>;
    public uploadFileUsingPOST(fileDto:fileDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<fileDto>>>;
    public uploadFileUsingPOST(fileDto:fileDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (fileDto === null) {
            throw new Error('Required parameter fileDto was null or undefined when calling uploadFileUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWT) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        
        // to determine the Content-Type header
        const consumes: string[] = [

        ];

        return this.httpClient.post<Array<fileDto>>(`${this.basePath}/filemanager/upload`, 
            fileDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    
    //Create or rename folders..
    public manageDirectoryUsingPOST(directoryDto:directoryDto, observe?: 'body', reportProgress?: boolean): Observable<Array<directoryDto>>;
    public manageDirectoryUsingPOST(directoryDto:directoryDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<directoryDto>>>;
    public manageDirectoryUsingPOST(directoryDto:directoryDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<directoryDto>>>;
    public manageDirectoryUsingPOST(directoryDto:directoryDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (directoryDto === null) {
            throw new Error('Required parameter directoryDto was null or undefined when calling manageDirectoryUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWT) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        
        // to determine the Content-Type header
        const consumes: string[] = [

        ];

        return this.httpClient.post<Array<directoryDto>>(`${this.basePath}/filemanager/directory`, 
            directoryDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    
    //GET FILE LIST.
    public getFilesUsingGET(path: string, observe?: 'body', reportProgress?: boolean): Observable<Array<fileDto>>;
    public getFilesUsingGET(path: string, observe?: 'response', reportProgress?: boolean): Observable<Array<fileDto>>;
    public getFilesUsingGET(path: string, observe?: 'events', reportProgress?: boolean): Observable<Array<fileDto>>;
    public getFilesUsingGET(path: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (JWT) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<fileDto>>(`${this.basePath}/filemanager/list?path=${path}`, 
        {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    //delete files.
    public deleteFileUsingPOST(fileDto:fileDto, observe?: 'body', reportProgress?: boolean): Observable<Array<fileDto>>;
    public deleteFileUsingPOST(fileDto:fileDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<fileDto>>>;
    public deleteFileUsingPOST(fileDto:fileDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<fileDto>>>;
    public deleteFileUsingPOST(fileDto:fileDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (fileDto === null) {
            throw new Error('Required parameter fileDto was null or undefined when calling deleteFileUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWT) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        
        // to determine the Content-Type header
        const consumes: string[] = [

        ];

        return this.httpClient.post<Array<fileDto>>(`${this.basePath}/filemanager/delete`, 
            fileDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
}
