// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  release: 'DEV',
  production: false,
  serverUrl: `${window.location.origin}/_service`,
  sso: {
    clientId: 'dlrhfMlXZ8IId9OqbK2IwBzfU60a',
    serverUrl: 'http://localhost/_service',
    issuer: '/oauth2/token',
    redirectUri: window.location.origin,
    scope: 'openid profile',
    tokenEndpoint:          '/oauth2/token',
    userinfoEndpoint:       '/oauth2/userinfo',
    authorizationEndpoint:  '/oauth2/authorize',
    jwksEndpoint:           '/oauth2/jwks',
    showDebugInformation: true,
    requireHttps: false,
    responseType: 'id_token token'
  }
};
