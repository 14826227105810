import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserTypeEnum } from 'src/app/enums/userType.enum';
import { IAccount } from '../models/iaccount.interface';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { TranslateService } from '@ngx-translate/core';

export const USER = 'userNor';

@Injectable({
  providedIn: 'root',
})
export class AppStoreService {
  private userSource = new BehaviorSubject<IAccount>(null);
  user$ = this.userSource.asObservable();

  constructor(private readonly translate: TranslateService) {
    
    const browserLang = this.translate.getBrowserLang();

    if(!Cookie.get('languageNor')) {
      //this.setLanguage(browserLang.match(/en|hu/) ? browserLang : 'en');
      this.setLanguage('hu');
    }
  }
  
  setUser(user: IAccount): void {
    this.userSource.next(user);
    localStorage.setItem(USER, JSON.stringify(user));
  }

  setUserFromResponse(resp: HttpResponse<any>, userEmail: string, userTypeParam: string, firstLogin:boolean): void {
    const accessToken = resp.headers.get('access_token');
    const userId = Number(resp.body.userId);
    const userType: UserTypeEnum = UserTypeEnum[userTypeParam];
    this.setUser({userEmail, userId, accessToken, userType, firstLogin});
  }

  get user(): IAccount {
    return this.userSource.getValue();
  }

  get userId(): number {
    if (this.userSource.getValue() && this.userSource.getValue().userId > 0) {
      return this.userSource.getValue().userId;
    }
    return -1;
  }

  get email(): string {
    if (this.userSource.getValue() && this.userSource.getValue().userEmail) {
      return this.userSource.getValue().userEmail;
    }
    return null;
  }

  get type(): string {
    if (this.userSource.getValue() && this.userSource.getValue().userType) {
      return this.userSource.getValue().userType;
    }
    return null;
  }

  isAdmin() {
    if (this.userSource.getValue() && this.userSource.getValue().userType) {
      return this.userSource.getValue().userType === UserTypeEnum.ADMIN;
    }
    return false;
  }

  isMember() {
    if (this.userSource.getValue() && this.userSource.getValue().userType) {
      return this.userSource.getValue().userType === UserTypeEnum.MEMBER;
    }
    return false;
  }
  
  isLogged()
  {
    if (this.userSource.getValue() && this.userSource.getValue().userType) {
      return true;
    }
    
    return false;
  }

  clear() {
    this.userSource.next(null);
    localStorage.removeItem(USER);
  }
  
  //Determine if device is ios
  iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }
  
  //Determine is device is android.
  android()
  {
    var ua = navigator.userAgent.toLowerCase();

    if(ua.indexOf("android") > -1) {
       return true;
    }
  }

  setLanguage(lang:string)
  {
    //localStorage.setItem('languageSkillX', lang);
    Cookie.set('languageNor', lang.toUpperCase(), 365, '/');
  }

  getLanguage()
  {
    let lang = Cookie.get('languageNor');
    
    return (lang ? lang:'EN');
  }

  isWebview()
  {
    if(typeof window['ReactNativeWebView'] != 'undefined') {
      return true;
    }

  }
}
