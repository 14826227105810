import { Injectable, OnDestroy } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { AbstractComponent } from '../classes/abstract-component';
import { IAccount } from '../models/iaccount.interface';
import { AppStoreService } from '../store/app-store.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService extends AbstractComponent implements OnDestroy, CanActivate {
  private user: IAccount = null;
  constructor(
    private readonly appStore: AppStoreService,
    private readonly router: Router,
  ) {
    super();
    this.createSubscriptions();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  createSubscriptions(): void {
    this.appStore.user$.pipe(takeUntil(this.unsubscribe)).subscribe((user: IAccount) => {
      if (user && user.userId > 0) {
        this.user = user;
      }
    });
  }

  canActivate(): boolean {
    if (!this.user || this.user && this.user.userId < 1) {
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }
}
