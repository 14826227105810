/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */


import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface excelDto {
  product:object;
  product_buddi:object;
  user_id:number;
}

export interface downloadDto {
  products:object;
}

export interface sendDocumentDto {
  file:string;
  email:string;
}

@Injectable({
    providedIn: 'root'
})
export class ProductControllerService {

    protected basePath = 'https://skillx-dev.molaris.local:8888';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    public updateProductExcelUsingPOST(excelDto:excelDto, observe?: 'body', reportProgress?: boolean): Observable<Array<excelDto>>;
    public updateProductExcelUsingPOST(excelDto:excelDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<excelDto>>>;
    public updateProductExcelUsingPOST(excelDto:excelDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<excelDto>>>;
    public updateProductExcelUsingPOST(excelDto:excelDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (excelDto === null) {
            throw new Error('Required parameter excelDto was null or undefined when calling updateProductExcelUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWT) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        
        // to determine the Content-Type header
        const consumes: string[] = [

        ];

        return this.httpClient.post<Array<excelDto>>(`${this.basePath}/productmanager/update`, 
            excelDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get product list
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductListUsingGET(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getProductListUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getProductListUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getProductListUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

      let headers = this.defaultHeaders;

      // authentication (JWT) required
      if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
          headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
      }

      // to determine the Accept header
      let httpHeaderAccepts: string[] = [
          '*/*'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
          headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
      ];

      return this.httpClient.get<any>(`${this.basePath}/product`,
          {
              withCredentials: this.configuration.withCredentials,
              headers: headers,
              observe: observe,
              reportProgress: reportProgress
          }
      );
    }


    /**
     * get product data
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductDataUsingGET(id:number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getProductDataUsingGET(id:number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getProductDataUsingGET(id:number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getProductDataUsingGET(id:number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

      let headers = this.defaultHeaders;

      // authentication (JWT) required
      if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
          headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
      }

      // to determine the Accept header
      let httpHeaderAccepts: string[] = [
          '*/*'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
          headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
      ];

      return this.httpClient.get<any>(`${this.basePath}/product/${encodeURIComponent(Number(id))}`,
          {
              withCredentials: this.configuration.withCredentials,
              headers: headers,
              observe: observe,
              reportProgress: reportProgress
          }
      );
    }

    /**
     * get Product buddy list
     * 
     * @param user_id number
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserBuddyListUsingGET(user_id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getUserBuddyListUsingGET(user_id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getUserBuddyListUsingGET(user_id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getUserBuddyListUsingGET(user_id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling getProductBuddyListUsingGET.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWT) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/productmanager/buddylist/${encodeURIComponent(Number(user_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }


    /**
     * Create downloadable ZIP
     * 
     * @param downladDtzt
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
    */

    public createZIPUsingPOST(downloadDto:downloadDto, observe?: 'body', reportProgress?: boolean): Observable<Array<downloadDto>>;
    public createZIPUsingPOST(downloadDto:downloadDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<downloadDto>>>;
    public createZIPUsingPOST(downloadDto:downloadDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<downloadDto>>>;
    public createZIPUsingPOST(downloadDto:downloadDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (downloadDto === null) {
            throw new Error('Required parameter downloadDto was null or undefined when calling createZIPUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWT) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        
        // to determine the Content-Type header
        const consumes: string[] = [

        ];

        return this.httpClient.post<Array<downloadDto>>(`${this.basePath}/product/createzip`, 
            downloadDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Send document..
     * 
     * @param downladDtzt
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
    */

    public sendDocumentUsingPOST(sendDocumentDto:sendDocumentDto, observe?: 'body', reportProgress?: boolean): Observable<Array<sendDocumentDto>>;
    public sendDocumentUsingPOST(sendDocumentDto:sendDocumentDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<sendDocumentDto>>>;
    public sendDocumentUsingPOST(sendDocumentDto:sendDocumentDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<sendDocumentDto>>>;
    public sendDocumentUsingPOST(sendDocumentDto:sendDocumentDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sendDocumentDto === null) {
            throw new Error('Required parameter sendDocumentDto was null or undefined when calling sendDocumentUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWT) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        
        // to determine the Content-Type header
        const consumes: string[] = [

        ];

        return this.httpClient.post<Array<sendDocumentDto>>(`${this.basePath}/product/send_document`, 
            sendDocumentDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
}
