/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface NotificationTypeDto { 
    code?: NotificationTypeDto.CodeEnum;
    descriptionCaption?: string;
    descriptionCode?: string;
    icon?: string;
    labour?: boolean;
    redirect?: string;
    titleCaption?: string;
    titleCode?: string;
    uuid?: string;
}
export namespace NotificationTypeDto {
    export type CodeEnum = 'APPLY_JOB' | 'DOWNLOADABLE_SKILLX_APP' | 'JOB_INTERVIEW' | 'NEW_JOB_OFFERING' | 'NEW_SELFKNOWLEDGE_TEST' | 'SUBSCRIPTION_ACTIVATED';
    export const CodeEnum = {
        APPLYJOB: 'APPLY_JOB' as CodeEnum,
        DOWNLOADABLESKILLXAPP: 'DOWNLOADABLE_SKILLX_APP' as CodeEnum,
        JOBINTERVIEW: 'JOB_INTERVIEW' as CodeEnum,
        NEWJOBOFFERING: 'NEW_JOB_OFFERING' as CodeEnum,
        NEWSELFKNOWLEDGETEST: 'NEW_SELFKNOWLEDGE_TEST' as CodeEnum,
        SUBSCRIPTIONACTIVATED: 'SUBSCRIPTION_ACTIVATED' as CodeEnum
    };
}
