import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { ApiModule } from './api';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { apiModuleConfiguration } from './config/ApiModuleConfiguration';
import { CustomTranslateLoader } from './shared/language-switcher/custom-translate-loader';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorI18nService } from './core/components/paginator/mat-paginator-i18n.service';
//import { MatSliderModule } from '@angular/material/slider';
import { SocketioService } from './socketio.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    CdkStepperModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      // translate
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader // translate .ts megoldáshoz
        // useFactory: HttpLoaderFactory, // translate .json-höz
        // deps: [HttpClient] // translate .json-höz
      }
    }), // translate
    AppRoutingModule,
    HttpClientModule,
    ApiModule.forRoot(apiModuleConfiguration),
    OAuthModule.forRoot(),
    //MatSliderModule
  ],
  providers: [ 
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorI18nService,
    },
    SocketioService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
