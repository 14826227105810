import { Subject } from 'rxjs';

export abstract class AbstractComponent {
  protected unsubscribe: Subject<void>;

  constructor() {
    this.unsubscribe = new Subject<void>();
  }

  abstract createSubscriptions(): void;
}
