import { Component, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { IAccount } from 'src/app/core/models/iaccount.interface';
import { AbstractComponent } from 'src/app/core/classes/abstract-component';
import { AppStoreService, USER } from './core/store/app-store.service';
import { SocketioService } from './socketio.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends AbstractComponent implements OnDestroy {
  title = 'skillX';

  
  constructor(private readonly appStoreService: AppStoreService,
    private socketService: SocketioService) {
    super();
    this.createSubscriptions();
    const user = localStorage.getItem(USER);
    if (user) {
      const userObject = JSON.parse(user);
      this.appStoreService.setUser(userObject);
    }
  }

  ngOnInit(){
  }

  ngOnDestroy(): void {
    this.socketService.disconnect();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  createSubscriptions(): void {
    this.appStoreService.user$.pipe(takeUntil(this.unsubscribe)).subscribe((user: IAccount) => {
      if (user && user.userId > 0) {
        //this.socketService.setupSocketConnection(user.accessToken);
      }
    });
  }

}
